@import url('https://db.onlinewebfonts.com/c/0a6ee448d1bd65c56f6cf256a7c6f20a?family=Neue+Haas+Grotesk+Text+Pro');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --purple-deep: rgb(10, 1, 24);
  --purple-darker: rgb(8, 0, 20);
  --purple-gradient: linear-gradient(180deg, rgba(88, 28, 242, 1) 0%, rgba(43, 6, 154, 1) 100%);
  --blue-gradient: linear-gradient(180deg, rgba(15, 23, 42, 1) 0%, rgba(12, 12, 35, 1) 100%);
}

@layer base {
  html {
    font-family: "Neue Haas Grotesk", system-ui, sans-serif;
    scroll-behavior: smooth;
    background: var(--purple-deep);
  }

  body {
    color: white;
    background: linear-gradient(
      to bottom,
      var(--purple-deep),
      var(--purple-darker)
    );
  }
}

.gradient-purple {
  background: var(--purple-gradient);
}

.gradient-blue {
  background: var(--blue-gradient);
}

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.parallax-container {
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  perspective: 10px;
  transform-style: preserve-3d;
}

.parallax-content {
  position: relative;
  transform-style: preserve-3d;
}

